<template>
  <div>
    <ButtonsIcon
      icon-name="IconInfo"
      class="variant-secondary outlined touch-auto pointer-events-auto"
      @click="setModalState(true)"
    ></ButtonsIcon>

    <Dialog
      :modal="modalState"
      modal-class="z-[999] p-4 md:p-10 lg:p-20"
      container-class="max-w-[720px] !h-fit !max-h-min "
      @onOverlayClick="setModalState(false)"
    >
      <div class="px-11 py-20 gradient">
        <p class="text-body-big text-white opacity-[0.87]">
          <slot name="description">
            {{ description }}
          </slot>
        </p>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { usePlayerUi } from "@/stores/playerUi";
const store = usePlayerUi();

const props = defineProps({
  media: Object,
});

const modalState = ref(false);

const description = computed(() => {
  if (!props.media) return null;
  switch (props.media.type) {
    case "Season":
      const { tvShowSeason, tvShowSeasons } = props.media;
      if (tvShowSeason) {
        return tvShowSeason.description;
      }

      if (Array.isArray(tvShowSeasons) && tvShowSeasons.length > 0) {
        return tvShowSeasons[0].description;
      }

      return props.media.description;
    case "Series":
      return props.media.tvShowSeries?.description;
    case "Movie":
      return (props.media.aggregatedVideo || props.media).description;
    default:
      return props.media.description;
  }
});

const setModalState = (val) => {
  modalState.value = val;
  store.preventHideUI = val;
  clearTimeout(store.hideTimeout);
};
</script>

<style lang="scss" scoped>
.gradient {
  background: radial-gradient(
      100% 80% at 100% 0%,
      rgba(208, 45, 46, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      130% 100% at 0% 100%,
      rgba(208, 45, 46, 0.5) 0%,
      rgba(0, 0, 0, 0) 87.68%
    );
}
</style>
